<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>
        
    <v-alert 
      v-show="task_status_is_submitted" transition="fade-transition" color="success" type="success"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px" 
    >
      Successfully updated!
    </v-alert>

    <v-alert v-show="pageIsError" transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px" color="error" type="error">
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" lg="12">
        <h1>Task Dashboard</h1>
      </v-col>
    </v-row>
    
    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" lg="12">
        <v-tabs v-model="tab">
          <v-tab href="#tab-today">
            Today
          </v-tab>

          <v-tab href="#tab-tomorrow">
            tomorrow
          </v-tab>

          <v-tab href="#tab-this-week">
            This Week 
          </v-tab>

          <v-tab href="#tab-filter-tasks">
            Filter Due Date
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-tabs-items v-model="tab" class="bordered">
          <v-tab-item value="tab-today">
            <v-container>
              <div class="my-5 d-flex align-center">
                <h4 class="mr-5">Sort By: </h4>
              
                <v-select 
                  v-model="sortBy" :items="sortItems" label="Choose Here..." 
                  @change="handleSortToday"
                  style="max-width: 300px"
                  flat hide-details solo 
                />
              </div>

              
              <v-expansion-panels multiple>
                <v-expansion-panel v-for="task_today in tasks_today" :key="task_today.customerId" class="my-5">
                  <v-expansion-panel-header class="pb-0">
                    <div class="d-flex align-start">
                      <div>
                        <div class="d-flex align-start">
                          <div>
                            <h4 v-for="customer in task_today.customers" :key="customer.id">
                              <v-icon>mdi-account-outline</v-icon> {{ customer.last_name + ', '+ customer.first_name  }}
                            </h4>
                          </div>
                          
                          <v-icon class="ml-5" title="View Deal" @click="$router.push({ path: `/dashboard/admin/deal/read/${task_today.deal.id}` })">mdi-magnify</v-icon>
                        </div>

                        <br />
                        
                        <div v-for="deal_summary in task_today.dealSummaries.length > 0 ? task_today.dealSummaries : ''" :key="deal_summary.id" class="mb-4 text-subtitle-1 grey--text">
                          {{ (task_today.deal.internal_name) }} - LTV: {{ (formatValue(deal_summary.loan_to_value, 'percentage')) }} -
                          {{ readMortgageePosition(deal_summary.mortgage_position) }} -
                          {{ formatValue(Number(deal_summary.amount_requested), 'currency') }} -
                          {{ deal_summary.city }}, {{ readProvinceCode(deal_summary.province) }}
                        </div>

                        <div v-if="task_today.dealSummaries.length === 0" class="grey--text">
                          No Deal Summaries
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  
                  <v-expansion-panel-content>
                    <v-card flat>
                      <v-card-text class="pt-0">
                        <v-expansion-panels multiple>
                          <v-expansion-panel v-for="objective in task_today.objectives" :key="objective.id" class="mt-2 rounded bordered">
                            <v-expansion-panel-header>
                              <div :style="{ color: getDays(objective.createdAt) - objective.day_limit > 0 ? '#f44336' : '#ffffff' }">
                                <p class="text-h6" style="width:350px; cursor:pointer" @click="
                                  $router.push({ 
                                    path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/read/${objective.id}`, 
                                  });
                                ">
                                  {{ objective.title }}   - 
                                    <span class="text-subtitle-1 grey--text">
                                      <v-icon class="text-subtitle-1 grey--text"> mdi-calendar-blank </v-icon> 
                                      {{ objective.due_date }} 
                                    </span>
                                </p>

                                <p class="mb-0 grey--text"> {{ objective.description }} </p>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card 
                                v-for="(task, index) in objective.stg_obj_tsks" :key="task.id" 
                                :disabled="isTaskDisabled(task_today.customerId, objective.id, task.id, 'today')" flat class="mt-5 rounded-lg bordered"
                              >
                                <v-card-title class="d-flex align-start"> 
                                  <div class="d-flex algin-center">
                                    <h4 :style="{ color: getDays(task.createdAt) - task.day_limit > 0 ? '#f44336' : '#ffffff' }" >{{ task.title }}</h4>
                                    <v-btn class="ml-2" title="Update Task" icon  @click="
                                      $router.push({ 
                                        path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                      });
                                    ">
                                      <v-icon>
                                        mdi-pencil-outline
                                      </v-icon>  
                                    </v-btn>
                                  </div>

                                  <v-spacer /> 
                                  
                                  <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="
                                          $router.push({ 
                                            path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                          });
                                        ">
                                          Update Task
                                        </v-list-item-title>
                                      </v-list-item>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="duplicate_task_today(objective.id, task.id)">
                                          Make Duplicate
                                        </v-list-item-title>
                                      </v-list-item>

                                    </v-list>
                                  </v-menu>  
                                </v-card-title>

                                <v-card-text> 
                                  <p class="grey--text">{{ task.description }} </p> 
                                  
                                  <p class="grey--text" title="Day Count">
                                    It's been {{ (getDays(task.createdAt)) }} {{ (getDays(task.createdAt) > 1 ? 'days' : 'day') }} since this task was created. 
                                  </p>

                                  <v-divider />

                                  <div class="mt-5 d-flex flex-wrap">
                                    <p class="mr-5" title="Due Date">
                                      <v-icon class="grey--text text-h6">mdi-calendar-blank</v-icon> Due: {{ task.due_date }}
                                    </p>

                                    <p v-if="getDays(task.createdAt) - task.day_limit > 0" class="error--text" title="Overdue Days">
                                      <v-icon class="text-h6" color="error">mdi-alert-outline</v-icon>
                                      Overdue: {{ getDays(task.createdAt) - task.day_limit > 1 ? getDays(task.createdAt) - task.day_limit + ' days' : getDays(task.createdAt) - task.day_limit + ' day' }}
                                    </p>
                                  </div>

                                  <v-divider />

                                  <p class="my-5 text-h6 white--text">Task Status</p>

                                  <div>
                                    <v-chip-group
                                      v-model="task.status"
                                      @change="updateTodayTaskStatus(task_today.customerId, objective.id, task.id, task.status)"
                                      active-class="teal darken-3 white--text"
                                      column
                                    >
                                      <v-chip value="To Do">To Do</v-chip>

                                      <v-chip value="On Progress">On Progress</v-chip>

                                      <v-chip value="Done">Done</v-chip>

                                    </v-chip-group>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                      
                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-video</v-icon> Meet Up
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-email-fast-outline</v-icon> Email
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-phone</v-icon> Call
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-tab-item>

          <v-tab-item value="tab-tomorrow">
            <v-container>
              <div class="my-5 d-flex align-center">
                <h4 class="mr-5">Sort By: </h4>
              
                <v-select 
                  v-model="sortBy" :items="sortItems" label="Choose Here..." 
                  @change="handleSortTomorrow"
                  style="max-width: 300px"
                  flat hide-details solo 
                />
              </div>

              <v-expansion-panels multiple>
                <v-expansion-panel v-for="task_tomorrow in tasks_tomorrow" :key="task_tomorrow.customerId">
                  <v-expansion-panel-header class="pb-0">
                    <div class="d-flex align-start">
                      <div>
                        <div class="d-flex align-start">
                          <div>
                            <h4 v-for="customer in task_tomorrow.customers" :key="customer.id">
                              <v-icon>mdi-account-outline</v-icon> {{ customer.last_name + ', '+ customer.first_name  }}
                            </h4>
                          </div>
                          
                          <v-icon class="ml-5" title="View Deal" @click="$router.push({ path: `/dashboard/admin/deal/read/${task_tomorrow.deal.id}` })">mdi-magnify</v-icon>
                        </div>

                        <br />
                        
                        <div v-for="deal_summary in task_tomorrow.dealSummaries.length > 0 ? task_tomorrow.dealSummaries : ''" :key="deal_summary.id" class="mb-4 text-subtitle-1 grey--text">
                          {{ (task_tomorrow.deal.internal_name) }} - LTV: {{ (formatValue(deal_summary.loan_to_value, 'percentage')) }} -
                          {{ readMortgageePosition(deal_summary.mortgage_position) }} -
                          {{ formatValue(Number(deal_summary.amount_requested), 'currency') }} -
                          {{ deal_summary.city }}, {{ readProvinceCode(deal_summary.province) }}
                        </div>

                        <div v-if="task_tomorrow.dealSummaries.length === 0" class="grey--text">
                          No Deal Summaries
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  
                  <v-expansion-panel-content>
                    <v-card flat>
                      <v-card-text class="pt-0">
                        <v-expansion-panels multiple>
                          <v-expansion-panel v-for="objective in task_tomorrow.objectives" :key="objective.id" class="mt-2 rounded bordered">
                            <v-expansion-panel-header>
                              <div :style="{ color: getDays(objective.createdAt) - objective.day_limit > 0 ? '#f44336' : '#ffffff' }">
                                <p class="text-h6" style="width:350px; cursor:pointer" @click="
                                  $router.push({ 
                                    path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/read/${objective.id}`, 
                                  });
                                ">
                                  {{ objective.title }} - 
                                  <span class="text-subtitle-1 grey--text"> 
                                    <v-icon class="text-subtitle-1 grey--text"> mdi-calendar-blank </v-icon> 
                                    Due: {{ objective.due_date }} 
                                  </span>
                                </p>

                                <p class="grey--text"> {{ objective.description }} </p>
                                
                                <p class="mb-0 grey--text" title="Day Count">
                                  It's been {{ (getDays(objective.createdAt)) }} {{ (getDays(objective.createdAt) > 1 ? 'days' : 'day') }} since this task was created. 
                                </p>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card 
                                v-for="(task, index) in objective.stg_obj_tsks" :key="task.id" 
                                :disabled="isTaskDisabled(task_tomorrow.customerId, objective.id, task.id, 'tomorrow')" flat class="mt-5 rounded-lg bordered"
                              >
                                <v-card-title class="d-flex align-start"> 
                                  <div class="d-flex align-center">
                                    <h4 :style="{ color: getDays(task.createdAt) - task.day_limit > 0 ? '#f44336' : '#ffffff' }" >{{ task.title }}</h4>
                                    
                                    <v-btn class="ml-2" title="Update Task" icon  @click="
                                      $router.push({ 
                                        path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                      });
                                    ">
                                      <v-icon>
                                        mdi-pencil-outline
                                      </v-icon>  
                                    </v-btn>
                                  </div>

                                  <v-spacer /> 
                                  
                                  <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="
                                          $router.push({ 
                                            path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                          });
                                        ">
                                          Update Task
                                        </v-list-item-title>
                                      </v-list-item>                                  

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="duplicate_task_tomorrow(objective.id, task.id)">
                                          Make Duplicate
                                        </v-list-item-title>
                                      </v-list-item>

                                    </v-list>
                                  </v-menu>  
                                </v-card-title>

                                <v-card-text> 
                                  <p class="grey--text">{{ task.description }} </p> 
                                  
                                  <p class="grey--text" title="Day Count">
                                    It's been {{ (getDays(task.createdAt)) }} {{ (getDays(task.createdAt) > 1 ? 'days' : 'day') }} since this task was created. 
                                  </p>

                                  <v-divider />

                                  <div class="mt-5 d-flex flex-wrap">
                                    <p class="mr-5" title="Due Date">
                                      <v-icon class="grey--text text-h6">mdi-calendar-blank</v-icon> Due: {{ task.due_date }}
                                    </p>

                                    <p v-if="getDays(task.createdAt) - task.day_limit > 0" class="error--text" title="Overdue Days">
                                      <v-icon class="text-h6" color="error">mdi-alert-outline</v-icon>
                                      Overdue: {{ getDays(task.createdAt) - task.day_limit > 1 ? getDays(task.createdAt) - task.day_limit + ' days' : getDays(task.createdAt) - task.day_limit + ' day' }}
                                    </p>
                                  </div>

                                  <v-divider />

                                  <p class="my-5 text-h6 white--text">Task Status</p>

                                  <div>
                                    <v-chip-group
                                      v-model="task.status"
                                      @change="updateTomorrowTaskStatus(task_tomorrow.customerId, objective.id, task.id, task.status)"
                                      active-class="teal darken-3 white--text"
                                      column
                                    >
                                      <v-chip value="To Do">To Do</v-chip>

                                      <v-chip value="On Progress">On Progress</v-chip>

                                      <v-chip value="Done">Done</v-chip>

                                    </v-chip-group>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                      
                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-video</v-icon> Meet Up
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-email-fast-outline</v-icon> Email
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-phone</v-icon> Call
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
           </v-tab-item>

          <v-tab-item value="tab-this-week">
            <v-container>
              <div class="my-5 d-flex align-center">
                <h4 class="mr-5">Sort By: </h4>
              
                <v-select 
                  v-model="sortBy" :items="sortItems" label="Choose Here..." 
                  @change="handleSortThisWeek"
                  style="max-width: 300px"
                  flat hide-details solo 
                />
              </div>

              <v-expansion-panels multiple>
                <v-expansion-panel v-for="task_this_week in tasks_this_week" :key="task_this_week.customerId" class="my-5">
                  <v-expansion-panel-header class="pb-0">
                    <div class="d-flex align-start">
                      <div>
                        <div class="d-flex align-start">
                          <div>
                            <h4 v-for="customer in task_this_week.customers" :key="customer.id">
                              <v-icon>mdi-account-outline</v-icon> {{ customer.last_name + ', '+ customer.first_name  }}
                            </h4>
                          </div>
                          
                          <v-icon class="ml-5" title="View Deal" @click="$router.push({ path: `/dashboard/admin/deal/read/${task_this_week.deal.id}` })">mdi-magnify</v-icon>
                        </div>

                        <br />
                        
                        <div v-for="deal_summary in task_this_week.dealSummaries.length > 0 ? task_this_week.dealSummaries : ''" :key="deal_summary.id" class="mb-4 text-subtitle-1 grey--text">
                          {{ (task_this_week.deal.internal_name) }} - LTV: {{ (formatValue(deal_summary.loan_to_value, 'percentage')) }} -
                          {{ readMortgageePosition(deal_summary.mortgage_position) }} -
                          {{ formatValue(Number(deal_summary.amount_requested), 'currency') }} -
                          {{ deal_summary.city }}, {{ readProvinceCode(deal_summary.province) }}
                        </div>

                        <div v-if="task_this_week.dealSummaries.length === 0" class="grey--text">
                          No Deal Summaries
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  
                  <v-expansion-panel-content>
                    <v-card flat>                  
                      <v-card-text class="pt-0">
                        <v-expansion-panels multiple>
                          <v-expansion-panel v-for="objective in task_this_week.objectives" :key="objective.id" class="mt-2 rounded bordered">
                            <v-expansion-panel-header>
                              <div :style="{ color: getDays(objective.createdAt) - objective.day_limit > 0 ? '#f44336' : '#ffffff' }">
                                <p class="text-h6" style="width:350px; cursor:pointer" @click="
                                  $router.push({ 
                                    path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/read/${objective.id}`, 
                                  });
                                ">
                                  {{ objective.title }} - 
                                    <span class="text-subtitle-1 grey--text"> 
                                      <v-icon class="text-subtitle-1 grey--text"> mdi-calendar-blank </v-icon> 
                                      {{ objective.due_date }}
                                    </span>
                                </p>

                                <p class="mb-0 grey--text"> {{ objective.description }} </p>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card 
                                v-for="(task, index) in objective.stg_obj_tsks" :key="task.id" 
                                :disabled="isTaskDisabled(task_this_week.customerId, objective.id, task.id, 'this_week')" flat class="mt-5 rounded-lg bordered"
                              >
                                <v-card-title class="d-flex align-start"> 
                                  <div class="d-flex align-center">
                                    <h4 :style="{ color: getDays(task.createdAt) - task.day_limit > 0 ? '#f44336' : '#ffffff' }">{{ task.title }}</h4>
                                    
                                    <v-btn class="ml-2" title="Update Task" icon  @click="
                                      $router.push({ 
                                        path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                      });
                                    ">
                                      <v-icon>
                                        mdi-pencil-outline
                                      </v-icon>  
                                    </v-btn>
                                  </div>

                                  <v-spacer /> 
                                  
                                  <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="
                                          $router.push({ 
                                            path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                          });
                                        ">
                                          Update Task
                                        </v-list-item-title>
                                      </v-list-item>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="duplicate_task_this_week(objective.id, task.id)">
                                          Make Duplicate
                                        </v-list-item-title>
                                      </v-list-item>

                                    </v-list>
                                  </v-menu>  
                                </v-card-title>

                                <v-card-text> 
                                  <p class="grey--text">{{ task.description }} </p> 
                                  
                                  <p class="grey--text" title="Day Count">
                                    It's been {{ (getDays(task.createdAt)) }} {{ (getDays(task.createdAt) > 1 ? 'days' : 'day') }} since this task was created. 
                                  </p>

                                  <v-divider />

                                  <div class="mt-5 d-flex flex-wrap">
                                    <p class="mr-5" title="Due Date">
                                      <v-icon class="grey--text text-h6">mdi-calendar-blank</v-icon> Due: {{ task.due_date }}
                                    </p>

                                    <p v-if="getDays(task.createdAt) - task.day_limit > 0" class="error--text" title="Overdue Days">
                                      <v-icon class="text-h6" color="error">mdi-alert-outline</v-icon>
                                      Overdue: {{ getDays(task.createdAt) - task.day_limit > 1 ? getDays(task.createdAt) - task.day_limit + ' days' : getDays(task.createdAt) - task.day_limit + ' day' }}
                                    </p>
                                  </div>

                                  <v-divider />

                                  <p class="my-5 text-h6 white--text">Task Status</p>

                                  <div>
                                    <v-chip-group
                                      v-model="task.status"
                                      @change="updateThisWeekTaskStatus(task_this_week.customerId, objective.id, task.id, task.status)"
                                      active-class="teal darken-3 white--text"
                                      column
                                    >
                                      <v-chip value="To Do">To Do</v-chip>

                                      <v-chip value="On Progress">On Progress</v-chip>

                                      <v-chip value="Done">Done</v-chip>

                                    </v-chip-group>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                      
                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-video</v-icon> Meet Up
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-email-fast-outline</v-icon> Email
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-phone</v-icon> Call
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-tab-item>

          <v-tab-item value="tab-filter-tasks">
            <v-container>
              <div class="d-flex align-center">
                Start Date: <v-text-field class="ml-2" type="date" v-model="startDate" flat hide-details solo/> 
                <span class="mx-5">-</span>
                End Date: <v-text-field class="ml-2 mr-5" type="date" v-model="endDate" flat hide-details solo/>
                <v-btn @click="filteredTask">submit</v-btn>
              </div>

              <v-expansion-panels multiple>
                <v-expansion-panel v-for="filtered_task in filtered_per_customers_task" :key="filtered_task.customerId" class="my-5">
                  <v-expansion-panel-header class="pb-0">
                    <div class="d-flex align-start">
                      <div>
                        <div class="d-flex align-start">
                          <div>
                            <h4 v-for="customer in filtered_task.customers" :key="customer.id">
                              <v-icon>mdi-account-outline</v-icon> {{ customer.last_name + ', '+ customer.first_name  }}
                            </h4>
                          </div>
                          
                          <v-icon class="ml-5" title="View Deal" @click="$router.push({ path: `/dashboard/admin/deal/read/${filtered_task.deal.id}` })">mdi-magnify</v-icon>
                        </div>

                        <br />
                        
                        <div v-for="deal_summary in filtered_task.dealSummaries.length > 0 ? filtered_task.dealSummaries : ''" :key="deal_summary.id" class="mb-4 text-subtitle-1 grey--text">
                          {{ (filtered_task.deal.internal_name) }} - LTV: {{ (formatValue(deal_summary.loan_to_value, 'percentage')) }} -
                          {{ readMortgageePosition(deal_summary.mortgage_position) }} -
                          {{ formatValue(Number(deal_summary.amount_requested), 'currency') }} -
                          {{ deal_summary.city }}, {{ readProvinceCode(deal_summary.province) }}
                        </div>

                        <div v-if="filtered_task.dealSummaries.length === 0" class="grey--text">
                          No Deal Summaries
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  
                  <v-expansion-panel-content>
                    <v-card flat>
                      <v-card-text class="pt-0">
                        <v-expansion-panels multiple>
                          <v-expansion-panel v-for="objective in filtered_task.objectives" :key="objective.id" class="mt-2 rounded bordered">
                            <v-expansion-panel-header>
                              <div :style="{ color: getDays(objective.createdAt) - objective.day_limit > 0 ? '#f44336' : '#ffffff' }">
                                <p class="text-h6" style="width:350px; cursor:pointer" @click="
                                  $router.push({ 
                                    path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/read/${objective.id}`, 
                                  });
                                ">
                                  {{ objective.title }} - 
                                    <span class="text-subtitle-1 grey--text"> 
                                      <v-icon class="text-subtitle-1 grey--text"> mdi-calendar-blank </v-icon> 
                                      {{ objective.due_date }}
                                    </span>
                                </p>

                                <p class="mb-0 grey--text"> {{ objective.description }} </p>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card 
                                v-for="(task, index) in objective.stg_obj_tsks" :key="task.id" 
                                :disabled="isTaskDisabled(filtered_task.customerId, objective.id, task.id, 'filtered_tasks')" flat class="mt-5 rounded-lg bordered"
                              >
                                <v-card-title class="d-flex align-start"> 
                                  <div class="d-flex align-center">
                                    <h4 :style="{ color: getDays(task.createdAt) - task.day_limit > 0 ? '#f44336' : '#ffffff' }">{{ task.title }}</h4>
                                    
                                    <v-btn class="ml-2" title="Update Task" icon  @click="
                                      $router.push({ 
                                        path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                      });
                                    ">
                                      <v-icon>
                                        mdi-pencil-outline
                                      </v-icon>  
                                    </v-btn>
                                  </div>

                                  <v-spacer /> 
                                  
                                  <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-horizontal</v-icon>
                                      </v-btn>
                                    </template>

                                    <v-list>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="
                                          $router.push({ 
                                            path: `/dashboard/admin/kanban/${objective.stg_itm.type}/stage-item/${objective.stg_itm.id}/objective/${objective.id}/task/read/${task.id}`, 
                                          });
                                        ">
                                          Update Task
                                        </v-list-item-title>
                                      </v-list-item>

                                      <v-list-item>
                                        <v-list-item-title style="cursor: pointer" @click="duplicate_task_due_date(objective.id, task.id)">
                                          Make Duplicate
                                        </v-list-item-title>
                                      </v-list-item>

                                    </v-list>
                                  </v-menu>  
                                </v-card-title>

                                <v-card-text> 
                                  <p class="grey--text">{{ task.description }} </p> 
                                  
                                  <p class="grey--text" title="Day Count">
                                    It's been {{ (getDays(task.createdAt)) }} {{ (getDays(task.createdAt) > 1 ? 'days' : 'day') }} since this task was created. 
                                  </p>

                                  <v-divider />

                                  <div class="mt-5 d-flex flex-wrap">
                                    <p class="mr-5" title="Due Date">
                                      <v-icon class="grey--text text-h6">mdi-calendar-blank</v-icon> Due: {{ task.due_date }}
                                    </p>

                                    <p v-if="getDays(task.createdAt) - task.day_limit > 0" class="error--text" title="Overdue Days">
                                      <v-icon class="text-h6" color="error">mdi-alert-outline</v-icon>
                                      Overdue: {{ getDays(task.createdAt) - task.day_limit > 1 ? getDays(task.createdAt) - task.day_limit + ' days' : getDays(task.createdAt) - task.day_limit + ' day' }}
                                    </p>
                                  </div>

                                  <v-divider />

                                  <p class="my-5 text-h6 white--text">Task Status</p>

                                  <div>
                                    <v-chip-group
                                      v-model="task.status"
                                      @change="updateThisWeekTaskStatus(filtered_task.customerId, objective.id, task.id, task.status)"
                                      active-class="teal darken-3 white--text"
                                      column
                                    >
                                      <v-chip value="To Do">To Do</v-chip>

                                      <v-chip value="On Progress">On Progress</v-chip>

                                      <v-chip value="Done">Done</v-chip>

                                    </v-chip-group>
                                  </div>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-card-text>
                      
                      <v-divider />

                      <v-card-actions>
                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-video</v-icon> Meet Up
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-email-fast-outline</v-icon> Email
                        </v-btn>

                        <v-btn
                          color="teal lighten-1"
                          text
                        >
                          <v-icon>mdi-phone</v-icon> Call
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from '@/plugins/API';

export default {
  data: () => ({
    tab: '',
    search: '',
    startDate: null,
    endDate: null,
    pageIsLoading: true,
    pageIsError: false,
    task_status_is_submitted: false,

    tasks_today: [],
    tasks_tomorrow: [],
    tasks_this_week: [],
    all_per_customers_task: [],
    filtered_per_customers_task: [],

    sortBy: '',

    sortItems: [ 
      { value: 'day_limit', text: 'Due Date' },
      { value: 'customer_name', text: 'Customer Name' },
      { value: 'internal_name', text: 'Deal' },
    ]
    
  }),

  methods: {
    filteredTask() {  
      if (!this.startDate || !this.endDate) {
        console.log('no date');
        return; // Return early if no date is selected
      }

      this.startDate = this.formatTaskFilterDate(this.startDate);
      this.endDate = this.formatTaskFilterDate(this.endDate);

      console.log('start date: ', this.startDate);
      console.log('end date: ', this.endDate);

      this.readMyTaskData();

      if (this.all_per_customers_task && this.all_per_customers_task.length > 0) {
        // filter grouped by customer if some of objectives are within the date range
        this.filtered_per_customers_task = this.all_per_customers_task.filter(customer_task => {
          return customer_task.objectives.some(objective => {
            const dueDate = new Date(objective.due_date);
            const start = new Date(this.startDate);
            const end = new Date(this.endDate);

            return dueDate.getTime() >= start.getTime() && dueDate.getTime() <= end.getTime();
          });
        });

        console.log('filtered per customers task: ',this.filtered_per_customers_task);

        this.filtered_per_customers_task.forEach(customer_task => {
          // filter objectives if the due_date is within the date range
          customer_task.objectives = customer_task.objectives.filter(objective => {
              const dueDate = new Date(objective.due_date);
              const start = new Date(this.startDate);
              const end = new Date(this.endDate);

              return start.getTime() <= dueDate.getTime()  && dueDate.getTime() <= end.getTime();
            });
          
          // filter tasks if the due_date is within the date range
          customer_task.objectives.forEach(objective => {
            objective.stg_obj_tsks = objective.stg_obj_tsks.filter(task => {
              const dueDate = new Date(task.due_date);
              const start = new Date(this.startDate);
              const end = new Date(this.endDate);

              return start.getTime() <= dueDate.getTime()  && dueDate.getTime() <= end.getTime();
            });
          });
        });
      
        console.log(this.filtered_per_customers_task);
      }
    },

    async duplicate_task_today(objective_id, task_id) {
      try {
        // create duplicate task
        const res = await API().post(`api/kanban/create_duplicate_stage_objective_task`, {
          objective_id: objective_id,
          task_id: task_id,
        });

        // display result
        console.log(res);

        if (res.status === 201) {
          // push the duplicated task to the tasks_today objectives data
          const found_per_customer_task = this.tasks_today.find(task_per_customer => {
            return task_per_customer.objectives.some(objective => objective.id === objective_id);
          });

          res.data.due_date =  this.convertDayLimit(res.data.createdAt, res.data.day_limit);

          found_per_customer_task.objectives.forEach(objective => {
            if (objective.id === objective_id) {
              objective.stg_obj_tsks.push(res.data);
            }
          });
        }

        if (res.status === 204) {
          console.log('Something wrong with the create_duplicate_stage_objective_task controller.');
          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async duplicate_task_tomorrow(objective_id, task_id) {
      try {
        // create duplicate task
        const res = await API().post(`api/kanban/create_duplicate_stage_objective_task`, {
          objective_id: objective_id,
          task_id: task_id,
        });

        // display result
        console.log(res);

        if (res.status === 201) {
          // push the duplicated task to the tasks_today objectives data
          const found_per_customer_task = this.tasks_tomorrow.find(task_per_customer => {
            return task_per_customer.objectives.some(objective => objective.id === objective_id);
          });

          res.data.due_date =  this.convertDayLimit(res.data.createdAt, res.data.day_limit);

          found_per_customer_task.objectives.forEach(objective => {
            if (objective.id === objective_id) {
              objective.stg_obj_tsks.push(res.data);
            }
          });
        }

        if (res.status === 204) {
          console.log('Something wrong with the create_duplicate_stage_objective_task controller.');
          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async duplicate_task_this_week(objective_id, task_id) {
      try {
        // create duplicate task
        const res = await API().post(`api/kanban/create_duplicate_stage_objective_task`, {
          objective_id: objective_id,
          task_id: task_id,
        });

        // display result
        console.log(res);

        if (res.status === 201) {
          // push the duplicated task to the tasks_today objectives data
          const found_per_customer_task = this.tasks_this_week.find(task_per_customer => {
            return task_per_customer.objectives.some(objective => objective.id === objective_id);
          });

          res.data.due_date =  this.convertDayLimit(res.data.createdAt, res.data.day_limit);

          found_per_customer_task.objectives.forEach(objective => {
            if (objective.id === objective_id) {
              objective.stg_obj_tsks.push(res.data);
            }
          });
        }

        if (res.status === 204) {
          console.log('Something wrong with the create_duplicate_stage_objective_task controller.');
          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async duplicate_task_due_date(objective_id, task_id) {
      try {
        // create duplicate task
        const res = await API().post(`api/kanban/create_duplicate_stage_objective_task`, {
          objective_id: objective_id,
          task_id: task_id,
        });

        // display result
        console.log(res);

        if (res.status === 201) {
          // push the duplicated task to the tasks_today objectives data
          const found_per_customer_task = this.filtered_per_customers_task.find(task_per_customer => {
            return task_per_customer.objectives.some(objective => objective.id === objective_id);
          });

          res.data.due_date =  this.convertDayLimit(res.data.createdAt, res.data.day_limit);

          found_per_customer_task.objectives.forEach(objective => {
            if (objective.id === objective_id) {
              objective.stg_obj_tsks.push(res.data);
            }
          });
        }

        if (res.status === 204) {
          console.log('Something wrong with the create_duplicate_stage_objective_task controller.');
          this.pageIsError = true;
          setTimeout(() => {
            this.pageIsError = false;
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    readProvinceCode(province_code) {
      switch(province_code) {
        case 'AB':
          return 'Alberta';

        case 'BC':
          return 'British Columbia';

        case 'ON':
          return 'Ontario';

        default: 
          return 'No province'
      }
    },
    
    formatValue(value, format) {
      switch (format) {
        case 'currency':
          return value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        case 'percentage':
          return value + '%';
        default:
          return value;
      }
    },
    
    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    formatTaskFilterDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as yyyy-mm-dd
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    },

    convertDayLimit(created, day_limit) {
      const date = new Date(created);
      date.setDate(date.getDate() + day_limit);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const result = `${month}/${day}/${year}`;
      return result;
    },

    readMortgageePosition(position) {
      switch(position) {
        case 1: 
          return '1st';
        case 2: 
          return '2nd';
        case 3: 
          return '3rd';
        case 4: 
          return '4th';
        default:
          return 'Position not found'
      }
    },

    handleSortToday() {
      switch (this.sortBy) {
        case 'day_limit': {
          this.tasks_today.forEach(task_today => {
            task_today.objectives.forEach(obj => {
              obj.stg_obj_tsks.sort((a, b) => {
                return new Date(a.due_date) - new Date(b.due_date);
              });
            });
          });
          break;
        }

        case 'customer_name':
          this.tasks_today.sort((a, b) => {
            return a.customers[0].last_name.localeCompare(b.customers[0].last_name);
          });
          break;

        case 'internal_name':
          this.tasks_today.sort((a, b) => {
            return a.deal.internal_name.localeCompare(b.deal.internal_name);
          });
          break;

        default:
          console.log('Error in sortBy.');
          break;
      }
    },

    handleSortTomorrow() {
      switch (this.sortBy) {
        case 'day_limit': {
          this.tasks_tomorrow.forEach(task_tomorrow => {
            task_tomorrow.objectives.forEach(objective => {
              objective.stg_obj_tsks.sort((a, b) => {
                return new Date(a.due_date) - new Date(b.due_date);
              });
            });
          });
          break;
        }

        case 'customer_name':
          this.tasks_tomorrow.sort((a, b) => {
            return a.customers[0].last_name.localeCompare(b.customers[0].last_name);
          });
          break;

        case 'internal_name':
          this.tasks_tomorrow.sort((a, b) => {
            return a.deal.internal_name.localeCompare(b.deal.internal_name);
          });
          break;

        default:
          console.log('Error in sortBy.');
          break;
      }
    },

    handleSortThisWeek() {
      switch (this.sortBy) {
        case 'day_limit': {
          this.tasks_this_week.forEach(task_this_week => {
            task_this_week.objectives.forEach(objective => {
              objective.stg_obj_tsks.sort((a, b) => {
                return new Date(a.due_date) - new Date(b.due_date);
              });
            });
          });
          break;
        }

        case 'customer_name':
          this.tasks_this_week.sort((a, b) => {
            return a.customers[0].last_name.localeCompare(b.customers[0].last_name);
          });
          break;

        case 'internal_name':
          this.tasks_this_week.sort((a, b) => {
            return a.deal.internal_name.localeCompare(b.deal.internal_name);
          });
          break;

        default:
          console.log('Error in sortBy.');
          break;
      }
    },

    isTaskDisabled(customer_id, objective_id, task_id, type) {
      switch(type) {
        case 'today': {
          const today_task = this.tasks_today.find(task => task.customerId === customer_id);
          const objective = today_task.objectives.find(objective => objective.id === objective_id);
          const task_index = objective.stg_obj_tsks.findIndex(task => task.id === task_id);
          
          const result = objective.stg_obj_tsks.some(task_predecessor => {
            if (task_predecessor.order === objective.stg_obj_tsks[task_index].predecessor) {
              return task_predecessor.status !== 'Done';
            }

            if (task_predecessor.predecessor === 0) {
              return false;
            }
          }) 
          
          return result;
        }

        case 'tomorrow': {
          const tomorrow_task = this.tasks_tomorrow.find(task => task.customerId === customer_id);
          const objective = tomorrow_task.objectives.find(objective => objective.id === objective_id);
          const task_index = objective.stg_obj_tsks.findIndex(task => task.id === task_id);
          
          const result = objective.stg_obj_tsks.some(task_predecessor => {
            if (task_predecessor.order === objective.stg_obj_tsks[task_index].predecessor) {
              return task_predecessor.status !== 'Done';
            }

            if (task_predecessor.predecessor === 0) {
              return false;
            }
          }) 
          
          return result;
        }

        case 'this_week': {
          const task_this_week = this.tasks_this_week.find(task => task.customerId === customer_id);
          const objective = task_this_week.objectives.find(objective => objective.id === objective_id);
          const task_index = objective.stg_obj_tsks.findIndex(task => task.id === task_id);
          
          const result = objective.stg_obj_tsks.some(task_predecessor => {
            if (task_predecessor.order === objective.stg_obj_tsks[task_index].predecessor) {
              return task_predecessor.status !== 'Done';
            }

            if (task_predecessor.predecessor === 0) {
              return false;
            }
          }) 
          
          return result;
        }

        case 'filtered_tasks': {
          const filtered_task = this.filtered_per_customers_task.find(task => task.customerId === customer_id);
          const objective = filtered_task.objectives.find(objective => objective.id === objective_id);
          const task_index = objective.stg_obj_tsks.findIndex(task => task.id === task_id);
          
          const result = objective.stg_obj_tsks.some(task_predecessor => {
            if (task_predecessor.order === objective.stg_obj_tsks[task_index].predecessor) {
              return task_predecessor.status !== 'Done';
            }

            if (task_predecessor.predecessor === 0) {
              return false;
            }
          }) 
          
          return result;
        }

        default: {
          console.log('Error in isTaskDisabled.');
          break;
        }
      }
    },

    readTaskToday(tasks_today) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to the beginning of today

      const tasks = tasks_today.filter(task => {
        const taskDate = new Date(task.stg_obj.activity_date);
        taskDate.setHours(0, 0, 0, 0); // Set time to the beginning of the task's date

        return taskDate <= today;
      });

      const grouped_by_customer = this.groupedByCustomer(tasks, 'today');

      return grouped_by_customer;
    },

    readTaskTomorrow(tasks_tomorrow) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const tasks = tasks_tomorrow.filter(task => {
        const taskDate = new Date(task.stg_obj.activity_date);
        return (
          taskDate.getDate() === tomorrow.getDate() &&
          taskDate.getMonth() === tomorrow.getMonth() &&
          taskDate.getFullYear() === tomorrow.getFullYear()
        );
      });
      
      const grouped_by_customer = this.groupedByCustomer(tasks, 'tomorrow');

      return grouped_by_customer;
    },

    readTaskThisWeek(tasks_this_week) {
      // Get the current date
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      // Get the seventh day from the current day
      const seventhDayFromCurrent = new Date(currentDate);
      seventhDayFromCurrent.setDate(seventhDayFromCurrent.getDate() + 6);

      // Filter tasks from the current date up to the seventh day
      const tasksForCurrentAndNextSevenDays = tasks_this_week.filter(task => {
        const taskDate = new Date(task.stg_obj.activity_date);
        taskDate.setHours(0, 0, 0, 0);
        
        return taskDate <= seventhDayFromCurrent;
      });

      const grouped_by_customer = this.groupedByCustomer(tasksForCurrentAndNextSevenDays, 'tomorrow');

      return grouped_by_customer;
    },

    groupedByCustomer(tasks, type) {
      const customers = tasks.reduce((result, task) => {
        const customerId = task.stg_obj.stg_itm.deal.customers[0].id;
        const customers = task.stg_obj.stg_itm.deal.customers;
        const deal = task.stg_obj.stg_itm.deal;
        const dealSummaries = task.stg_obj.stg_itm.deal.deal_summaries; 
        const existingCustomers = result.find(item => item.customerId === customerId);

        if (existingCustomers) {
          const existingObjective = existingCustomers.objectives.some(objective => objective.id === task.stg_obj.id);
          if (!existingObjective) existingCustomers.objectives.push(task.stg_obj);
        } else {
          result.push({ customerId, deal, customers, dealSummaries, objectives: [task.stg_obj] });
        }

        return result;
      }, []); 

      customers.forEach(customer => {
        customer.objectives.forEach(objective => {
          objective.due_date = this.convertDayLimit(objective.createdAt, objective.day_limit);

          objective.stg_obj_tsks.forEach(task => {
            task.due_date = this.convertDayLimit(task.createdAt, task.day_limit);
          });
        });
      });

      return customers;
    },

    async updateTodayTaskStatus(customer_id, objective_id, task_id, status) {
      try {
        const task_per_customer = this.tasks_today.find(task_per_customer => task_per_customer.customerId === customer_id);
        const objective = task_per_customer.objectives.find(objective => objective.id === objective_id);
        const task = objective.stg_obj_tsks.find(task => task.id === task_id);

        task.status = status;

        const update_res = await API()
          .patch('api/kanban/update_stage_objective_task', 
            {
              user_id: this.$store.getters['Auth/getAuthUser'].id,
              stg_itm_id: objective.stg_itm.id,
              stg_obj_tsk: task,
              note: '',
            }
          )
        
        console.log(update_res);
        
        if(update_res.status === 201) {
          this.task_status_is_submitted = true;

          setTimeout(() => {
            this.task_status_is_submitted = false;
          }, 2000);
        }

      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async updateTomorrowTaskStatus(customer_id, objective_id, task_id, status) {
      try {
        const task_per_customer = this.tasks_tomorrow.find(task_per_customer => task_per_customer.customerId === customer_id);
        const objective = task_per_customer.objectives.find(objective => objective.id === objective_id);
        const task = objective.stg_obj_tsks.find(task => task.id === task_id);

        task.status = status;

        const update_res = await API()
          .patch('api/kanban/update_stage_objective_task', 
            {
              user_id: this.$store.getters['Auth/getAuthUser'].id,
              stg_itm_id: objective.stg_itm.id,
              stg_obj_tsk: task,
              note: '',
            }
          )
        
        console.log(update_res);
        
        if(update_res.status === 201) {
          this.task_status_is_submitted = true;

          setTimeout(() => {
            this.task_status_is_submitted = false;
          }, 2000);
        }

      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async updateThisWeekTaskStatus(customer_id, objective_id, task_id, status) {
      try {
        const task_per_customer = this.tasks_this_week.find(task_per_customer => task_per_customer.customerId === customer_id);
        const objective = task_per_customer.objectives.find(objective => objective.id === objective_id);
        const task = objective.stg_obj_tsks.find(task => task.id === task_id);

        task.status = status;

        const update_res = await API()
          .patch('api/kanban/update_stage_objective_task', 
            {
              user_id: this.$store.getters['Auth/getAuthUser'].id,
              stg_itm_id: objective.stg_itm.id,
              stg_obj_tsk: task,
              note: '',
            }
          )
        
        console.log(update_res);
        
        if(update_res.status === 201) {
          this.task_status_is_submitted = true;

          setTimeout(() => {
            this.task_status_is_submitted = false;
          }, 2000);
        }

      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },

    async readMyTaskData() {
      try {
        this.pageIsLoading = true;
        const read_internal_user_tasks = await API().get(`api/kanban/read_internal_user_task?user_id=${this.$store.getters['Auth/getAuthUser'].id}`);
        console.log(read_internal_user_tasks);

        const tasks = read_internal_user_tasks.data.stg_obj_tsks;

        const taskTomorrow = this.readTaskTomorrow(tasks);
        const tasksToday = this.readTaskToday(tasks);
        const tasksThisWeek = this.readTaskThisWeek(tasks);
        const all_per_customers_task = this.groupedByCustomer(tasks);

        console.log('taskTomorrow: ', taskTomorrow);
        console.log('tasksToday: ', tasksToday);
        console.log('tasksThisWeek: ', tasksThisWeek);
        console.log('allTasks: ', tasksThisWeek);

        this.tasks_today = tasksToday;
        this.tasks_tomorrow = taskTomorrow;
        this.tasks_this_week = tasksThisWeek;
        this.all_per_customers_task = all_per_customers_task;

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    }
  },

  mounted() {
    this.readMyTaskData();
  }

};
</script>

<style>
  .bordered{
    border: 1px solid #3c3c3c !important;
    background-color: transparent !important;
    padding: 5px;
  }
</style>
